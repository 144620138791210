
import {
  defineComponent,
  reactive,
  SetupContext,
  toRefs,
  onBeforeMount,
  computed,
} from 'vue';
import resource from '@/modules/resource';
import { TenureProp } from '@/types/Investment';
import investmentModule from '@/modules/investment/investmentModule';
import validation from '@/modules/validation';
import { addDays, format } from 'date-fns';

export default defineComponent({
  inheritAttrs: false,
  emits: ['changeTab', 'navigateToInvestmentDetails'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData } = resource();
    const { tenures, getAllTenures } = investmentModule();
    const { validateForm } = validation();

    const data = reactive({
      form: basicForm([
        {
          name: 'investment_amount',
          rules: 'required|number|min:50000',
        },
        {
          name: 'investment_tenure',
          rules: 'required',
        },
      ]),
    });

    const tenureOptions = computed(() =>
      tenures.value?.map((tenure: TenureProp) => {
        return {
          title: `${tenure?.days} Days - ${tenure?.percentage}%`,
          value: tenure?.id,
        };
      }),
    );

    const investmentBreakdown = computed(() => {
      const tenure: any = tenures.value?.find(
        (prop: any) => prop?.id === data.form.data.investment_tenure.value,
      );

      const amount = data.form.data.investment_amount.value;
      const potentialEarnings =
        amount * (tenure?.percentage / 100) * (tenure?.days / 365);

      return {
        potential_earnings: potentialEarnings || 0,
        amount: amount,
        interest_rate: tenure?.percentage || 0,
        maturity_date: format(
          addDays(new Date(), tenure?.days ?? 30),
          'do MMMM, yyyy',
        ),
      };
    });

    const back = () => emit('changeTab', 'create');

    const navigateToDetails = () => {
      if (!validateForm(data.form)) return false;
      emit('navigateToInvestmentDetails', getFormData(data.form));
    };

    onBeforeMount(getAllTenures);

    return {
      ...toRefs(data),
      back,
      navigateToDetails,
      tenureOptions,
      investmentBreakdown,
    };
  },
});
