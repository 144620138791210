<template>
  <div class="inline-flex items-center mb-3 cursor-pointer" @click="back">
    <icon-back />
    <span class="ml-2 text-xs text-invest-purple">Back</span>
  </div>
  <div class="text-2xl font-bold text-invest-primary">Investment Amount</div>
  <p class="mt-2 text-xs text-invest-dark">How much do you want to invest?</p>
  <div class="mt-5">
    <form @submit.prevent="navigateToDetails">
      <form-input
        type="currency"
        name="investment_amount"
        :form="form"
        v-model="form.data.investment_amount.value"
      >
        Investment amount
      </form-input>
      <form-input
        type="select"
        :form="form"
        name="investment_tenure"
        :options="tenureOptions"
        v-model="form.data.investment_tenure.value"
      >
        Select Tenure
      </form-input>

      <div class="mt-12">
        <h3 class="text-xs font-semibold">Investment Breakdown</h3>

        <div class="investment-details">
          <p class="text-xs">Principal amount</p>
          <span class="text-xs text-invest-primary">
            {{ $filters.formatAmount(investmentBreakdown.amount) }}
          </span>
        </div>

        <div class="investment-details">
          <p class="text-xs">Interest rate</p>
          <span class="text-xs text-invest-primary">
            {{ investmentBreakdown.interest_rate }}% annually
          </span>
        </div>

        <div class="investment-details">
          <p class="text-xs">Potential earnings</p>
          <span class="text-xs text-invest-primary">
            {{ $filters.formatAmount(investmentBreakdown.potential_earnings) }}
          </span>
        </div>

        <div class="investment-details">
          <p class="text-xs">Maturity date</p>
          <span class="text-xs text-invest-primary">
            {{ investmentBreakdown.maturity_date }}
          </span>
        </div>
      </div>

      <div class="grid grid-cols-1 mt-8 place-content-end">
        <button
          type="submit"
          class="btn btn-primary"
          @click="navigateToDetails"
        >
          Continue
        </button>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  SetupContext,
  toRefs,
  onBeforeMount,
  computed,
} from 'vue';
import resource from '@/modules/resource';
import { TenureProp } from '@/types/Investment';
import investmentModule from '@/modules/investment/investmentModule';
import validation from '@/modules/validation';
import { addDays, format } from 'date-fns';

export default defineComponent({
  inheritAttrs: false,
  emits: ['changeTab', 'navigateToInvestmentDetails'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData } = resource();
    const { tenures, getAllTenures } = investmentModule();
    const { validateForm } = validation();

    const data = reactive({
      form: basicForm([
        {
          name: 'investment_amount',
          rules: 'required|number|min:50000',
        },
        {
          name: 'investment_tenure',
          rules: 'required',
        },
      ]),
    });

    const tenureOptions = computed(() =>
      tenures.value?.map((tenure: TenureProp) => {
        return {
          title: `${tenure?.days} Days - ${tenure?.percentage}%`,
          value: tenure?.id,
        };
      }),
    );

    const investmentBreakdown = computed(() => {
      const tenure: any = tenures.value?.find(
        (prop: any) => prop?.id === data.form.data.investment_tenure.value,
      );

      const amount = data.form.data.investment_amount.value;
      const potentialEarnings =
        amount * (tenure?.percentage / 100) * (tenure?.days / 365);

      return {
        potential_earnings: potentialEarnings || 0,
        amount: amount,
        interest_rate: tenure?.percentage || 0,
        maturity_date: format(
          addDays(new Date(), tenure?.days ?? 30),
          'do MMMM, yyyy',
        ),
      };
    });

    const back = () => emit('changeTab', 'create');

    const navigateToDetails = () => {
      if (!validateForm(data.form)) return false;
      emit('navigateToInvestmentDetails', getFormData(data.form));
    };

    onBeforeMount(getAllTenures);

    return {
      ...toRefs(data),
      back,
      navigateToDetails,
      tenureOptions,
      investmentBreakdown,
    };
  },
});
</script>
